exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-get-quote-js": () => import("./../../../src/pages/get-quote.js" /* webpackChunkName: "component---src-pages-get-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-interchange-js": () => import("./../../../src/pages/pricing/interchange.js" /* webpackChunkName: "component---src-pages-pricing-interchange-js" */),
  "component---src-pages-pricing-personalized-js": () => import("./../../../src/pages/pricing/personalized.js" /* webpackChunkName: "component---src-pages-pricing-personalized-js" */),
  "component---src-pages-services-business-loans-js": () => import("./../../../src/pages/services/business-loans.js" /* webpackChunkName: "component---src-pages-services-business-loans-js" */),
  "component---src-pages-services-leases-js": () => import("./../../../src/pages/services/leases.js" /* webpackChunkName: "component---src-pages-services-leases-js" */),
  "component---src-pages-services-pos-js": () => import("./../../../src/pages/services/pos.js" /* webpackChunkName: "component---src-pages-services-pos-js" */),
  "component---src-pages-solutions-advantageous-pricing-js": () => import("./../../../src/pages/solutions/advantageous-pricing.js" /* webpackChunkName: "component---src-pages-solutions-advantageous-pricing-js" */),
  "component---src-pages-solutions-ecomm-solutions-js": () => import("./../../../src/pages/solutions/ecomm-solutions.js" /* webpackChunkName: "component---src-pages-solutions-ecomm-solutions-js" */),
  "component---src-pages-solutions-smart-terminals-js": () => import("./../../../src/pages/solutions/smart-terminals.js" /* webpackChunkName: "component---src-pages-solutions-smart-terminals-js" */),
  "component---src-pages-solutions-standard-terminals-js": () => import("./../../../src/pages/solutions/standard-terminals.js" /* webpackChunkName: "component---src-pages-solutions-standard-terminals-js" */),
  "component---src-pages-solutions-surcharge-program-js": () => import("./../../../src/pages/solutions/surcharge-program.js" /* webpackChunkName: "component---src-pages-solutions-surcharge-program-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

